$(function(){
    var mapStyle = [];
    
    function initMap() {
        var loc = new google.maps.LatLng( parseFloat( $('#map, #map2, #map3, #map4').data('lat')), parseFloat( $('#map, #map2, #map3, #map4').data('lng')) ); 
        
        var mapOptions = {
            zoom: parseInt($('#map, #map2, #map3, #map4').data('zoom')),
            center: loc,
            styles: mapStyle
        }; 
        var map = new google.maps.Map(document.getElementById('map'), mapOptions);        
        var image1 = 'images/map-icon.png';                
        var marker = new google.maps.Marker({
            position: loc,
            map: map,
            icon: image1
        });

        
        var map = new google.maps.Map(document.getElementById('map2'), mapOptions);
        var map = new google.maps.Map(document.getElementById('map3'), mapOptions); 
        var map = new google.maps.Map(document.getElementById('map4'), mapOptions);
    }

    if ($('#map').length) { initMap(); }
    //if ($('#map2').length) { initMap(); }
    //if ($('#map3').length) { initMap(); }
    //if ($('#map4').length) { initMap(); }

});