$(document).ready(function() {   
    
    // Stock Slider - Home Slider
    $('.banner').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.slideSet').children('.slide').clone();

            scope.find('.slideSet').flickity({
                cellAlign: 'right',
                groupCells: '100%',
                autoPlay: 7000,
                pageDots: true,
                prevNextButtons: false,
            });            
        });
    });

    // Stock Slider - Home Slider
    $('.single-slider').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.single-slider-set').children('.single-slider-cell').clone();

            scope.find('.single-slider-set').flickity({
                cellAlign: 'right',
                groupCells: '100%',
                autoPlay: 7000,
                pageDots: false,
                prevNextButtons: true,
            });            
        });
    });


    // Stock Slider - Multiple Product Slider
    $('.group-slider').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.sliderRow').children('.slider-cell').clone();

            scope.find('.sliderRow').flickity({
                cellAlign: 'center',
                autoPlay: 7000, 
                wrapAround: true,                
                groupCells: true,
                pageDots: true,
                prevNextButtons: true,
            });            
        });
    });

   

    // Thumbnail Slider - LED Product Details Page
    $('.thumbnailSlider').each(function(){
        var scope = $(this);

        // 1st carousel, main
        $('.larg-slider').flickity({
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
        });

        // 2nd carousel, navigation
        $('.larg-slider-nav').flickity({
            asNavFor: '.larg-slider',
            contain: true,
            pageDots: false,
            prevNextButtons: false,
        });
    });
    

    // Fancybox Slider - Product Details Page
    $('[data-fancybox]').fancybox({
      loop : false,
      keyboard : true,
      arrows : true,      
      toolbar : true,
      protect : true,
      animationEffect : "zoom",     
      
      buttons : [        
        'close'
        ],
    });
    
});